exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-artist-template-js": () => import("./../../../src/templates/artist-template.js" /* webpackChunkName: "component---src-templates-artist-template-js" */),
  "component---src-templates-presents-archive-js": () => import("./../../../src/templates/presents-archive.js" /* webpackChunkName: "component---src-templates-presents-archive-js" */),
  "component---src-templates-record-archive-js": () => import("./../../../src/templates/record-archive.js" /* webpackChunkName: "component---src-templates-record-archive-js" */),
  "component---src-templates-record-template-js": () => import("./../../../src/templates/record-template.js" /* webpackChunkName: "component---src-templates-record-template-js" */),
  "component---src-templates-year-template-js": () => import("./../../../src/templates/year-template.js" /* webpackChunkName: "component---src-templates-year-template-js" */)
}

